.footer-main{
    display: flex;
    flex-direction: column;
    background-color: #002C54;
    margin-top: 60px;

    width: 100%;
    height: 200px;
    color: #FDF6F6;
}

.footer-head{
    margin-top: 10px;
}