.greets-main{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
}

.greets-main-title {
    color:#C5001A;
    font-size: 70px;
    font-family:Verdana;
    font-weight: 600;
}

.greets-main-logobot{
    margin-top: 20px;
}

.greets-main-punchline {
    color:#C5001A;
    font-size: 25px;
    font-family:Verdana;
    font-weight: 600;
    width: 100%;
}

.greets-product-main{
    margin-top: 40px;
    margin-bottom: 30px;
    color:#002C54;
    font-size: 20px;
    font-family:Verdana;
    font-weight: 600;
    width: 100%;
}

.greets-product-list{
    width: 100%;
}

@media(max-width: 500px){
    .greets-main-logotop{
        width:200px;
    }
    .greets-main-logobot{
        width:150px;
    }
    .greets-main-title {
        font-size: 40px;
    }
    .greets-main{
        margin-top: 20px;
    }

    .greets-main-punchline {
        font-size: 10px;

    }
    
}
